import { Link } from '@remix-run/react'
import cn from 'classnames'

import { upTo } from '@/utils/enumerator'
import type { Page, Pagination } from '@/utils/types'

type Props = {
  pagination: Pagination
  scroll?: boolean
}

export default function Paginator({ pagination }: Props) {
  const { hasNext, hasPrev, page, totalPages } = pagination

  return (
    <div className='mt-6 flex items-center justify-between gap-x-2 border p-3 font-semibold sm:justify-start'>
      {!hasPrev && <span className='text-secondary'>← Previous</span>}
      {hasPrev && (
        <Link prefetch='intent' rel='prev' to={pageUrl(pagination.url, page - 1)}>
          ← Previous
        </Link>
      )}

      <div className='hidden items-center gap-x-2 sm:flex'>
        {Array.from(upTo(totalPages)).map((i) => (
          <Link
            className={cn({
              ['text-orange underline decoration-orange-500/50 underline-offset-4']: i + 1 === page
            })}
            to={pageUrl(pagination.url, i + 1)}
            key={i}
          >
            {i + 1}
          </Link>
        ))}
      </div>

      {hasNext && (
        <Link prefetch='intent' rel='next' to={pageUrl(pagination.url, page + 1)}>
          Next →
        </Link>
      )}
      {!hasNext && <span className='text-secondary'>Next →</span>}
    </div>
  )
}

function pageUrl(base: string, page: number) {
  const url = new URL(base)

  if (page === 1) {
    url.searchParams.delete('page')
  } else {
    url.searchParams.set('page', String(page))
  }

  return url.pathname + url.search
}

export function paginate<T>(data: T[], { page: { page: maybePage, url }, perPage }: { page: Page; perPage: number }) {
  const page = maybePage ? Math.max(Number(maybePage), 1) : 1
  const totalPages = Math.ceil(data.length / perPage)

  const pagination: Pagination = {
    page: page,
    hasPrev: page > 1,
    hasNext: page < totalPages,
    totalPages: totalPages,
    url: url
  }

  const start = (page - 1) * perPage
  const end = start + perPage

  return { data: data.slice(start, end), pagination: pagination }
}
